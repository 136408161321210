var featuresSection = document.querySelector('section.features');
var features = featuresSection.querySelectorAll('.extra-feature');
document.addEventListener('DOMContentLoaded', function () {
    var getThreshHold = function () {
        if (innerWidth <= 375)
            return 0.3;
        if (innerWidth <= 768)
            return 0.4;
        if (innerWidth <= 1024)
            return 0.5;
        if (innerWidth <= 1440)
            return 0.6;
        return 0.7;
    };
    var observer = new IntersectionObserver(function (_a) {
        var entry = _a[0];
        if (entry && entry.isIntersecting) {
            features.forEach(function (feature) { return (feature.className += ' active'); });
            observer.unobserve(entry.target);
        }
    }, {
        threshold: getThreshHold(),
    });
    observer.observe(featuresSection);
});
var extraFeaturesSection = document.querySelector('section.features-list');
var extraFeatures = extraFeaturesSection.querySelectorAll('.extra-feature#transform');
var perfectDateSection = document.querySelector('section.perfect-date');
var dateImgBg = document.querySelector('.date-img-bg');
var dateImgs = document.querySelectorAll('.date-img');
window.onscroll = function () {
    var transform = Math.max(extraFeaturesSection.getBoundingClientRect().top -
        (innerWidth > 1024 ? 450 : 200), 0) / 2;
    extraFeatures.forEach(function (elem, index) {
        if (innerWidth > 1024) {
            if (index === 0) {
                elem.style.transform = "translateX(".concat(transform, "px)");
            }
            if (index === 1) {
                elem.style.transform = "translateY(".concat(transform, "px)");
            }
            if (index === 2) {
                elem.style.transform = "translateX(-".concat(transform, "px)");
            }
        }
        else {
            if (index === 0) {
                elem.style.transform = "translateX(".concat(transform, "px)");
            }
            if (index === 1) {
                elem.style.transform = "translateX(".concat(-transform, "px)");
            }
            if (index === 2) {
                elem.style.transform = "translateX(".concat(transform, "px)");
            }
        }
    });
    var imgsTop = Math.floor(Math.max(perfectDateSection.getBoundingClientRect().top - innerHeight / 3));
    if (imgsTop >= 500 || imgsTop <= -1000)
        return;
    dateImgs.forEach(function (elem) { return (elem.style.top = "".concat(Math.max(0, Math.floor(imgsTop / 5)), "%")); });
    dateImgBg.style.opacity = "".concat(Math.min(100, Math.max(0, 100 - imgsTop / 3)), "%");
};
var reviewRows = document.querySelectorAll('.data-row');
var reviewRowActive = 0;
var setReviewClass = function (index) {
    reviewRows.forEach(function (content, idx0) {
        if (index === idx0)
            return (content.className = 'data-row active');
        if (index - 1 === idx0)
            return (content.className = 'data-row prev');
        return (content.className = 'data-row');
    });
};
document.addEventListener('DOMContentLoaded', function () {
    setReviewClass(0);
    setInterval(function () {
        if (reviewRowActive + 1 >= reviewRows.length) {
            reviewRowActive = 0;
            setReviewClass(0);
            return;
        }
        setReviewClass(reviewRowActive + 1);
        reviewRowActive += 1;
        return;
    }, 4000);
});
var faqSection = document.querySelector('section.faq');
var faqRows = document.querySelectorAll('.faq-row');
document.addEventListener('DOMContentLoaded', function () {
    var observer = new IntersectionObserver(function (_a) {
        var entry = _a[0];
        if (entry && entry.isIntersecting) {
            faqSection.className += ' active';
            observer.unobserve(entry.target);
        }
    }, {
        rootMargin: '-200px',
    });
    observer.observe(faqSection);
});
document.addEventListener('DOMContentLoaded', function () {
    var observer = new IntersectionObserver(function (_a) {
        var entry = _a[0];
        if (entry && entry.isIntersecting) {
            entry.target.className += ' active';
            // @ts-ignore
            var target = entry.target;
            target.style.height = "".concat(target.scrollHeight + 10, "px");
            observer.unobserve(entry.target);
        }
    }, {
        rootMargin: '-200px',
    });
    faqRows.forEach(function (elem) {
        var q = elem.querySelector('.faq-q');
        observer.observe(elem);
        elem.addEventListener('click', function () {
            if (elem.classList.contains('active')) {
                elem.className = 'faq-row';
                elem.style.height = "calc(1rem + ".concat(q.getBoundingClientRect().height * 1.5, "px)");
                return;
            }
            elem.style.height = "".concat(elem.scrollHeight + 10, "px");
            elem.className = 'faq-row active';
        });
    });
});
var contactTitleHover = document.querySelector('span.text-hover#contact-hover');
var contactSubtitleHover = document.querySelector('span.text-hover#contact-sub-hover');
document.onscroll = function () {
    var titleScrolled = contactTitleHover.getBoundingClientRect().top - innerHeight;
    var subtitleScrolled = contactSubtitleHover.getBoundingClientRect().top - innerHeight;
    if (titleScrolled - 100 <= 0) {
        contactTitleHover.style.width = "".concat(Math.min(100, -titleScrolled * 0.2), "%");
    }
    if (subtitleScrolled - 100 <= 0) {
        contactSubtitleHover.style.width = "".concat(Math.min(100, -subtitleScrolled * 0.2), "%");
    }
};
var downloadSection = document.querySelector('section.download');
var downloadWrapper = document.querySelector('.download-wrapper');
var downloadImgFirst = document.querySelector('#phone-img1');
var downloadImgSec = document.querySelector('#phone-img2');
var MAX_PHONE_TRANSFORMX = 75;
var MAX_PHONE_ROTATEX = 13;
document.addEventListener('DOMContentLoaded', function () {
    var observer = new IntersectionObserver(function (_a) {
        var entry = _a[0];
        if (entry && entry.isIntersecting) {
            downloadWrapper.style.boxShadow = "rgb(236, 15, 15,0.5) 0px 7px ".concat(Math.max(10, Math.min(Math.floor(entry.intersectionRatio *
                entry.intersectionRatio *
                75), 74)), "px 0px");
            downloadImgFirst.style.transform = "rotate(".concat(Math.min(entry.intersectionRatio * 20, MAX_PHONE_ROTATEX), "deg)\n                scale(0.8)\n                translateX(").concat(Math.min(MAX_PHONE_TRANSFORMX, entry.intersectionRatio * 100), "%)\n                ");
            downloadImgSec.style.transform = "rotate(-".concat(Math.min(entry.intersectionRatio * 20, MAX_PHONE_ROTATEX), "deg)\n                scale(0.8)\n                translateX(-").concat(Math.min(MAX_PHONE_TRANSFORMX, entry.intersectionRatio * 100), "%)\n                ");
        }
    }, {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    });
    observer.observe(downloadSection);
});
